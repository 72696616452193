import React, { useEffect, useState } from "react";
import { buildParser, buildParserFile } from "@lezer/generator";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { lezer } from "@codemirror/lang-lezer";
// import { foldNodeProp, foldInside, indentNodeProp } from "@codemirror/language";
import { styleTags, tags as t, Tag } from "@lezer/highlight";
import { LRLanguage, LanguageSupport } from "@codemirror/language";
import { completeFromList } from "@codemirror/autocomplete";

// TS error handling nicities
// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

// END TS error handling nicities

function App() {
  const [grammarText, setGrammarText] = useState("");
  const [parserText, setParserText] = useState("");
  const [extensions, setExtensions] = useState<LanguageSupport[]>([]);

  useEffect(() => {
    if (grammarText === "") {
      setParserText("");
    } else {
      try {
        const parserContent = buildParserFile(grammarText, {
          includeNames: true,
        });
        setParserText(parserContent.parser + parserContent.terms);

        const parser = buildParser(grammarText);

        const tree = parser.parse("ST|204|0001~");
        console.log(tree);

        // TODO: allow configuration of these values from the UI
        let parserWithMetadata = parser.configure({
          props: [
            styleTags({
              Identifier: t.variableName,
              SegmentName: t.lineComment,
              Segment: t.lineComment,
              Boolean: t.bool,
              String: t.lineComment,
              LineComment: t.lineComment,
              "( )": t.paren,
              "~": t.lineComment,
              "*": t.lineComment,
            }),
            // indentNodeProp.add({
            //   Application: (context) =>
            //     context.column(context.node.from) + context.unit,
            // }),
            // foldNodeProp.add({
            //   Application: foldInside,
            // }),
          ],
        });

        const exampleLanguage = LRLanguage.define({
          parser: parserWithMetadata,
          languageData: {
            commentTokens: { line: ";" },
          },
        });

        const exampleCompletion = exampleLanguage.data.of({
          autocomplete: completeFromList([
            { label: "defun", type: "keyword" },
            { label: "defvar", type: "keyword" },
            { label: "let", type: "keyword" },
            { label: "cons", type: "function" },
            { label: "car", type: "function" },
            { label: "cdr", type: "function" },
          ]),
        });

        const example = () => {
          return new LanguageSupport(exampleLanguage, [exampleCompletion]);
        };

        setExtensions([example()]);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setParserText(errorMessage);
      }
    }
  }, [grammarText]);

  return (
    <div className="p-12">
      <h1 className="text-3xl font-bold">Lezer Playground</h1>
      <p>
        Create or edit your{" "}
        <a
          className="text-blue-600"
          href="https://codemirror.net/examples/lang-package/"
        >
          Lezer grammar
        </a>{" "}
        with instant feedback . For use with CodeMirror. Start typing your
        grammar and you'll see parser.js getting re-generated as your type. Use
        your target language's sample text to verify things are working as
        expected.
      </p>

      <div className="container mx-auto px-4 py-8 flex flex-row">
        <div className="basis-1/2 px-4">
          <p>Grammar</p>
          <CodeMirror
            value={grammarText}
            height="300px"
            maxWidth="40vh"
            extensions={[lezer()]}
            onChange={(value) => {
              setGrammarText(value);
            }}
          />
        </div>

        {/* TODO: move parser.js content to a tab or show/hide UX */}
        {/* show success/failure status + error messages as grammar is being edited */}
        <div className="basis-1/2 px-4">
          <p>Parser.js</p>
          <CodeMirror
            value={parserText}
            height="300px"
            maxWidth="40vh"
            extensions={[javascript()]}
          />
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        <p>Sample text</p>
        <CodeMirror
          value=""
          height="300px"
          maxWidth="80vh"
          extensions={extensions}
        />
      </div>
    </div>
  );
}

export default App;
